<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <div class="col-md-12 maintenance">
          <div class="auth-card">
            <div class="card-body">
              <img
                class="img-fluid"
                src="../assets/images/logo/logo_landing.svg"
              />
              <h1>
                {{ isAuthorize ? $t("utils.chatbot.authorized") : $t("utils.chatbot.title")  }}
              </h1>
              <p v-if="error" class="f-16 text-danger">
                {{ error }}
              </p>
              <p v-else class="f-14">
                <span class="text-primary f-w-700"> {{ username || userEmail }}</span>
                {{ message }}
              </p>

              <div class="form-group text-center" v-if="isAuthorize || error">
                <a class="to-home be-btn primary blue w-100 lg" href="/">
                  {{ $t("utils.go_to_home_page") }}
                </a>
              </div>
              <div class="form-group text-center" v-else>
                <BaseButton
                  :loading="loading"
                  type="button"
                  @click.prevent="handleChatbotAuthorizationForm"
                  :showLoading="true"
                  buttonClass="be-btn blue primary font-weight-bold w-100"
                >
                  {{ $t("utils.chatbot.btn") }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/layouts/Footer";

export default {
  name: "BotAccessAuthorize",
  components: { Footer },
  data() {
    return {
      loading: false,
      isAuthorize: false,
      error: null
    };
  },
  computed: {
    ...mapGetters("auth", ["username", "userEmail"]),
    message() {
      return this.isAuthorize ?  this.$t("utils.chatbot.authorized_message") : this.$t("utils.chatbot.message");
    },
  },
  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },
  methods: {
    handleChatbotAuthorizationForm() {
      this.error = null;
      this.loading = true;
      const signature = this.$route.params.signature;
      this.$store
        .dispatch(`auth/chatbotAuthorization`, signature)
        .then(() => {
          this.isAuthorize = true;
          this.loading = false;
        })
        .catch(err => {
          if (err && err.response?.data?.message) {
            this.error = err.response.data.message;
          } else
            this.error = err.message

          this.loading = false;
        });
      }
  },
};
</script>

<style scoped src="../assets/landing/css/style.css"></style>
