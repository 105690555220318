import { render, staticRenderFns } from "./BotAccessAuthorize.vue?vue&type=template&id=198987c2&scoped=true&"
import script from "./BotAccessAuthorize.vue?vue&type=script&lang=js&"
export * from "./BotAccessAuthorize.vue?vue&type=script&lang=js&"
import style0 from "../assets/landing/css/style.css?vue&type=style&index=0&id=198987c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198987c2",
  null
  
)

export default component.exports